$(function () {
    $('body').delegate('.add-to-favorites', 'click', function (e) {
        e.preventDefault();
        var id = $(this).data('id');
        let url;

        if ($(this).hasClass('added')) {
            url = '/user-area/favorites/ajax/delete/' + id;
            $(this).removeClass('added');
        } else {
            url = '/user-area/favorites/ajax/add/' + id;
            $(this).addClass('added');
        }

        $.ajax({
            type: "GET",
            url: url,
            success: function (data) {}
        });
    });

    if ($('#favorites-group-view').length > 0) {
        $('.add-to-favorites').addClass('added');
    }

    if ($('.user-dashboard-link.user-logged-in').length > 0) {
        window.addEventListener('propertiesLoadedEvent', function (e) {
            markFavorites(e);
        });
    }
});

function markFavorites(e) {
    e.preventDefault();

    $.ajax({
        type: 'POST',
        url: favoritesGetRoute,
        success: function (r) {
            if (r.data !== undefined) {
                if (r.data.length > 0) {
                    $('.contact-info i.fa-heart').addClass('fas').removeClass('far');

                    r.data.forEach(function (id, index) {
                        var property_card = document.querySelector('a.add-to-favorites[data-id="' + id + '"]');
                        if (property_card != null) {
                            $(property_card).addClass('added');
                        }
                    });
                }
            }
        }
    });
}

function removeFromFavorites(id) {
    $('.property-card').find('[data-id="' + id + '"]').removeClass('added');
}

$('#favorites-group-view .btn-send-to-friend').on('click', function (e) {
    e.preventDefault();

    $.ajax({
        type: 'POST',
        url: $(this).attr('href'),
        success: function (data) {
            $('#send-to-friend-modal-wrapper').append(data);
            $('#send-to-friend-modal').modal();
        }
    });
});

$('#favorites-group-view').delegate('#add-email-btn', 'click', function (e) {
    e.preventDefault();
    if ($('#send-to-friend-modal .additional-email').hasClass('show')) {
        $('#send-to-friend-modal .additional-email').removeClass('show');
    } else {
        $('#send-to-friend-modal .additional-email').addClass('show');
    }
});

$('#favorites-group-view').delegate('#send-to-friend-query-form', 'submit', function (e) {
    e.preventDefault();
    var form = $(this);

    $.ajax({
        type: 'POST',
        url: '/user-area/send-message/ajax/form',
        data: form.serialize(),

        success: function (data) {
            form.fadeOut().siblings('.alert-success').removeClass('d-none').addClass('show');
            // $('#send-to-friend-modal button.close').trigger('click');
        },
        error: function () {
            form.siblings('.alert-danger').removeClass('d-none').addClass('show');
        }
    });
});

$('#favorites-group-view .favorites-notification-btn').on('click', function (e) {
    e.preventDefault();
    var path = $(this).attr('data-path');

    $.ajax({
        type: 'POST',
        url: path,
        success: function (data) {
            $('body').prepend(data);
            $('#notification-favorites-modal').modal();
        }
    });
});

$('#favorites-group-view').delegate('.favorites-notification-frequency', 'submit', function (e) {
    e.preventDefault();
    var form = $(this);
    var value = form.find('option:selected').text();

    $.ajax({
        type: 'POST',
        url: $('#favorites-group-view .favorites-notification-btn').attr('data-path'),
        data: form.serialize(),
        success: function () {
            $('.notification-favorites-modal .alert-success .frequency').html(value);

            form.find('.save-favorite-frequency-btn').removeAttr('disabled').find('i').removeClass('fa-spin');
            form.fadeOut().siblings('.alert-success').removeClass('d-none').fadeIn().find('strong').html(form.find('input').val());
        },
        error: function (data) {
            form.find('.save-favorite-frequency-btn').removeAttr('disabled').find('i').removeClass('fa-spin');
            form.siblings('.alert-danger').removeClass('d-none').fadeIn();
        }
    });
});

$('#favorites-group-view').delegate('.favorites-notification-cancel-btn', 'click', function (e) {
    e.preventDefault();
    var form = $(this);

    $.ajax({
        type: 'POST',
        url: $('#favorites-group-view .favorites-notification-cancel-btn').attr('data-path'),
        success: function (data) {
            $('#notification-favorites-modal button.close').trigger('click');
        },
        error: function () {
            form.siblings('.alert-danger').removeClass('d-none').addClass('show');
        }
    });
});